export default [

  // *===============================================---*
  // *--------- USER ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/dashboard/students',
    name: 'students',
    component: () => import('@/views/apps/user/users-list/UsersList.vue'),
  },
  {
    path: '/dashboard/users/view/:id',
    name: 'apps-users-view',
    component: () => import('@/views/apps/user/users-view/UsersView.vue'),
  },
  {
    path: '/dashboard/users/edit/:id',
    name: 'apps-users-edit',
    component: () => import('@/views/apps/user/users-edit/UsersEdit.vue'),
  },

  // Facilitators
  {
    path: '/dashboard/facilitators',
    name: 'facilitators',
    component: () => import('@/views/apps/tutor/tutor-list/TutorsList.vue'),
  },
  {
    path: '/dashboard/facilitators/view/:id',
    name: 'apps-tutors-view',
    component: () => import('@/views/apps/tutor/tutor-view/TutorsView.vue'),
  },
  {
    path: '/dashboard/facilitators/edit/:id',
    name: 'apps-tutors-edit',
    component: () => import('@/views/apps/tutor/tutor-edit/TutorsEdit.vue'),
  },

  // Invoice
  {
    path: '/dashboard/invoice/list',
    name: 'apps-invoice-list',
    component: () => import('@/views/apps/invoice/invoice-list/InvoiceList.vue'),
  },
  {
    path: '/dashboard/invoice/preview/:id',
    name: 'apps-invoice-preview',
    component: () => import('@/views/apps/invoice/invoice-preview/InvoicePreview.vue'),
  },
  {
    path: '/dashboard/invoice/add/',
    name: 'apps-invoice-add',
    component: () => import('@/views/apps/invoice/invoice-add/InvoiceAdd.vue'),
  },
  {
    path: '/dashboard/invoice/edit/:id',
    name: 'apps-invoice-edit',
    component: () => import('@/views/apps/invoice/invoice-edit/InvoiceEdit.vue'),
  },

  // Transactions
  {
    path: '/dashboard/transactions/',
    name: 'apps-transactions',
    component: () => import('@/views/apps/transactions/Transactions.vue'),
  },

  // Categories
  {
    path: '/dashboard/categories/',
    name: 'categories',
    component: () => import('@/views/apps/category/Categories.vue'),
  },
  {
    path: '/dashboard/categories/edit/:id',
    name: 'apps-category-edit',
    component: () => import('@/views/apps/category/EditCategory.vue'),
  },

  // Services
  {
    path: '/dashboard/courses/',
    name: 'courses',
    component: () => import('@/views/apps/category/Services.vue'),
  },
  {
    path: '/dashboard/services/edit/:id',
    name: 'course-edit',
    component: () => import('@/views/apps/category/EditService.vue'),
  },


  {
    path: '/dashboard/events/',
    name: 'events',
    component: () => import('@/views/apps/services/Events.vue'),
  },

  {
    path: '/dashboard/events/:id',
    name: 'event-details',
    component: () => import('@/views/apps/services/EventDetails.vue'),
  },

  {
    path: '/dashboard/testimonials/',
    name: 'testimonials',
    component: () => import('@/views/apps/services/Testimonial.vue'),
  },

  {
    path: '/dashboard/banners/',
    name: 'banners',
    component: () => import('@/views/apps/services/Banners.vue'),
  },

  {
    path: '/dashboard/brands/',
    name: 'brands',
    component: () => import('@/views/apps/services/Brands.vue'),
  },

  {
    path: '/dashboard/Settings/',
    name: 'apps-settings',
    component: () => import('@/views/apps/settings/Settings.vue'),
  },
]
